import { redirect } from '@sveltejs/kit';
import type { Load } from "@sveltejs/kit"

/** @type {import('./$types').PageLoad} */
export const load: Load = ({ url }) => {
	if (!url.pathname.endsWith('/')) {
		throw redirect(301, url.pathname + '/');
	}
  
  return { url: url.pathname }
};

export const trailingSlash = 'always';